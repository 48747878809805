import { Game } from './Game';

export class PreLoader {
    private game: Game;
    private path: string;
    private antiCache: boolean;
    private imageList: Array<string> = [];
    private images: Array<HTMLImageElement> = [];
    private readyCount: number = 0;
    private cb: CallableFunction;

    constructor(game: Game) {
      this.game = game;
    }

    public setPath(path: string, antiCache: boolean = false) {
      this.path = path;
      this.antiCache = antiCache
    }

    public preload(list: Array<string>, cb?: CallableFunction) {
      this.cb = cb;
      this.imageList = list;
      this.imageList.forEach(imgname => {
        this.addImage(imgname);
      })
    }

    private addImage(name: string) {
      const img = new Image();
      img.src = this.path + name + (this.antiCache ? '?' + Date.now() : '');
      img.addEventListener('load', (e) => this.onload(img, name))
      img.addEventListener('error', (e) => this.onloadError(img, name))
      this.images.push(img);
    }

    private onload(image: HTMLImageElement, name: string) {
      this.readyCount++;
      this.check();
    }

    private onloadError(image: HTMLImageElement, name: string) {
      console.error('image', this.path + name, 'not found');
      this.readyCount++;
      this.check();
    }

    private check() {
      if (this.readyCount === this.imageList.length) {
        if (this.cb) {
          this.cb();
        }
      }
    }
}
