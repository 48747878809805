import { Building } from "./Building";
import { Bullet } from "./Bullet";
import { patchConfig } from "./Config";
import { Creator } from "./Creator";
import { IConfig, GameConfigDefaults } from "./GameConfig";
import { IBox, convertBoxToPolygon } from "./Intersect";
import { Pipe } from "./Pipe";
import { disableTouch, setEvent } from "./Platform";
import { Platform } from "./GamePlatform";
import { PreLoader } from "./PreLoader";
import { Virus } from "./Virus";

export type GameState =
  | "init"
  | "ready"
  | "splash"
  | "game"
  | "score"
  | "showsplash";

export class Game {
  private sounds = {
    gameStartSound: undefined,
    jumpSound: undefined,
    bulletSound: undefined,
    passPipeSound: undefined,
    virusSound: undefined,
    levelUpSound: undefined,
  };

  private $config: IConfig;

  private $enableDebugMode = false;
  private $enableCreatorMode = false;

  // Used for css customization
  private $avatarSize = {
    playerWidth: 0,
    playerHeight: 0,
  };

  // Dynamic state
  private $state: {
    velocity: number;
    position: number;
    rotation: number;
    deploySpeed: number;
    activeLevel: number;
    sc: number;
    gameState: GameState;
    timer: number; // Used for endurence score
    levelStep: number; // Position in the level
    pauseState: boolean;
    virusSwapper: number;
    startTime: number;
    lastDeployTime: number;
    speed: number;
  };

  private $levelData = [];

  private $bounding: {
    flyAreaHeight: number;
    groundTop: number;
    ceilBottom: number; // Position of ceiling for gameover
  };

  // Config or dynamic
  private $levels: Array<string> = []; // Map of objects in game

  // Active images
  private $pipes: Array<Pipe> = []; // Active pipes

  private $bullets: Array<Bullet> = []; // Active bullets

  private $elm = {
    player: document.getElementById("player") as HTMLDivElement,
    flyArea: document.getElementById("flyarea") as HTMLDivElement,
    score: document.getElementById("bigscore") as HTMLDivElement,
  };

  // loops
  private loopGameloop: number;
  private cleanLoop: number;
  private buildingTimeout: number;

  // Preload image area
  private $images = [];

  private platform: Platform;
  private creatorControler: Creator;
  private buildingControler: Building = new Building(this);
  private preLoader = new PreLoader(this);

  private $virusses: Array<Virus> = []; // Active virussed
  virusses() {
    return this.$virusses;
  }

  bounding() {
    return this.$bounding;
  }

  speed() {
    return this.$state.speed;
  }

  gameState() {
    return this.$state.gameState;
  }

  isPaused() {
    return this.$state.pauseState;
  }

  config(): IConfig {
    return this.$config;
  }

  pipes() {
    return this.$pipes;
  }

  addElement(object: HTMLDivElement) {
    this.$elm.flyArea.append(object);
  }

  constructor() {
    disableTouch(); // Disable all touch events for scaling etc.

    // If creatormode parameter is set, debug info will be shown
    if (
      this.$enableCreatorMode ||
      window.location.search.search("creator") !== -1
    ) {
      this.$enableCreatorMode = true;
      this.creatorControler = new Creator(this);
    }

    this.loadConfig();

    this.preloadImages();

    this.$elm.score.style.fontFamily = this.$config.scoreFont;
    this.$elm.score.style.fontSize = this.$config.scoreSize + "px";
    this.$elm.score.style.color = this.$config.scoreColor;
    this.$elm.score.style.top = this.$config.scoreOffsetY + "px";

    this.$bounding = {
      flyAreaHeight: document.getElementById("flyarea").offsetHeight,
      groundTop: document.getElementById("land").offsetTop,
      ceilBottom:
        document.getElementById("ceiling").offsetTop +
        document.getElementById("ceiling").offsetHeight,
    };

    // Check debugmode. If turned on debug info will be shown
    if (window.location.search.search("debug") !== -1 || this.$config.debug) {
      this.$enableDebugMode = true;
    }

    // Show creator area on screen
    if (this.$enableCreatorMode) {
      this.creatorControler.init();
    }

    // register touch and click events on document
    this.registerEvents();

    this.platform = new Platform(); // Load the playandwin platform

    this.platform.preloadSounds(this.$config);
    // Register this game in the platform and wait for callback
    this.platform.init(this, this.$config);
  }

  playSound(soundKey: string, loop: boolean = false) {
    const config = this.config() as any;
    if (config[soundKey + "Sound"])
      this.platform.playSound(config.path + "/" + config[soundKey + "Sound"]);
  }

  public setLevels(levels: Array<string>) {
    this.$levels = levels;
  }

  private onPlatFormInit() {
    this.showGame();
    this.readyToPlay();
  }

  private registerEvents() {
    // Handle space bar
    document.addEventListener("keydown", (e: KeyboardEvent) => {
      // space bar!
      if (e.code === "Space") {
        if (this.$state.gameState === "game") {
          // Clicks are only triggered while playing
          this.screenClick();
        }
      }

      // Pause option is available but not working
      if (e.code === "KeyP") {
        this.pause();
      }
    });

    // Register the main clickevent
    setEvent(document, (e: MouseEvent) => this.screenClick(e));
  }

  space() {
    if (this.$state.gameState === "game") {
      // Clicks are only triggered while playing
      this.screenClick();
    }
  }

  private loadConfig() {
    const urlParams = new URLSearchParams(window.location.search);

    this.$config = GameConfigDefaults;

    patchConfig(this.$config);

    this.$state = {
      activeLevel: 0, // Start in level 0
      deploySpeed: (550 * 135) / this.$config.speed, // Start speed
      position: this.$config.startPosition, // Start position
      rotation: 40, // Start rotation
      velocity: 0, // Start wihtout velocity?
      gameState: "init", // Initial state
      levelStep: 0, // Initial step
      sc: 0, // Initial score
      timer: 0, // Initial timer
      pauseState: false, // Not paused
      virusSwapper: 0,
      lastDeployTime: new Date().getTime(),
      startTime: new Date().getTime(),
      speed: this.$config.speed,
    };

    // Load levels from config
    this.$levels = this.$config.levels;

    this.$avatarSize = {
      playerWidth: this.$config.playerSpriteWidth * this.$config.playerScale,
      playerHeight: this.$config.playerSpriteHeight * this.$config.playerScale,
    };

    this.patchCss();
  }

  private patchCss() {
    const sheet = document.createElement("style");
    // if creator mode, add anti cache headers

    const bulletStart = this.$config.bulletStartMoveX;
    const bulletEnd = 400;
    const bulletSpeed = 85 * 3;
    const bulletDuration = ((bulletEnd - bulletStart) / bulletSpeed) * 1000;

    document.getElementById("grass").style.animationDuration =
      (737 / this.speed()) * 2000 + "ms";
    document.getElementById("land").style.animationDuration =
      (737 / this.speed()) * 1000 + "ms";

    sheet.innerHTML =
      this.backgroundImage(".image-ground", this.$config.groundSprite) +
      this.backgroundImage(".image-grass", this.$config.grassSprite) +
      this.backgroundImage(".image-molen", this.$config.millSprite) +
      this.backgroundImage(".image-stadion", this.$config.stadiumSprite) +
      this.backgroundImage(".image-splash", this.$config.splashSprite) +
      this.backgroundImage(".image-koeman", this.$config.playerSprite) +
      this.backgroundImage(
        ".image-dood",
        this.$config.playerDeadSprite || this.$config.playerSprite
      ) +
      this.backgroundImage(".image-bullet", this.$config.bulletSprite) +
      this.backgroundImage(".image-corona", this.$config.virus1Sprite) +
      this.backgroundImage(".image-corona-alt", this.$config.virus2Sprite) +
      this.backgroundImage(".image-corona-alt2", this.$config.virus3Sprite) +
      this.backgroundImage(".image-pijp-boven", this.$config.topPipeSprite) +
      this.backgroundImage(
        ".image-pijp-beneden",
        this.$config.bottomPipeSprite
      ) +
      `

        #player {
          width: ` +
      this.$avatarSize.playerWidth +
      `px;
          height: ` +
      this.$avatarSize.playerHeight +
      `px;
        }



		  .splashcontent {
                background-position: 50% ${
                  this.$config.splashY === "center"
                    ? "50"
                    : this.$config.splashY
                }%;
                background-size: ${
                  this.$config.splashY === "center"
                    ? "100% 100%"
                    : this.$config.splashScale + "%"
                };
				opacity: ${this.$config.splashOpacity}%;
				z-index: 1000;

            }

        #gamescreen.start #player {
          left: -` +
      (this.$avatarSize.playerWidth + 0) +
      `px;
        }
        .bullet {
          max-height: ` +
      15 * this.$config.bulletScale +
      `px;
          max-width: ` +
      15 * this.$config.bulletScale +
      `px;
          height: ` +
      15 * this.$config.bulletScale +
      `px;
          width: ` +
      15 * this.$config.bulletScale +
      `px;
          sleft: ` +
      bulletEnd +
      `px;
          animation: animBullet ` +
      bulletDuration +
      `ms linear;
        }

        .molen {
          transform: scale(${this.$config.millScale});
          bottom: ${this.$config.millY}px;
        }

        .stadion {
          transform: scale(${this.$config.stadiumScale});
          bottom: ${this.$config.stadiumY}px;
        }

        #land {
          height: ${this.$config.groundHeight}px
        }

        #grass {
          bottom: ${this.$config.grassY}px
        }



        .virus {
         max-height: ` +
      80 * this.$config.virusScale +
      `px;
         max-width: ` +
      80 * this.$config.virusScale +
      `px;
         height: ` +
      80 * this.$config.virusScale +
      `px;
          width: ` +
      80 * this.$config.virusScale +
      `px;
        }

        @keyframes animBullet {
          0% { left: ` +
      (this.$config.playerSpriteWidth * this.$config.playerScale +
        bulletStart +
        10) +
      `px; }
          100% { left: ` +
      bulletEnd +
      `px; }
        }
        `;
    document.body.appendChild(sheet);
  }

  private backgroundImage(className: string, filename: string) {
    const path = this.$config.path;
    const cache = "";
    return (
      className +
      " { background-image: url('" +
      path +
      "/" +
      filename +
      cache +
      "')}"
    );
  }

  private preloadImages() {
    const imageList = [
      this.$config.playerSprite,
      this.$config.playerDeadSprite,
      this.$config.bulletSprite,
      this.$config.virus1Sprite,
      this.$config.virus2Sprite,
      this.$config.virus3Sprite,
      this.$config.splashSprite,
      this.$config.millSprite,
      this.$config.grassSprite,
      this.$config.groundSprite,
      this.$config.stadiumSprite,
      this.$config.topPipeSprite,
      this.$config.bottomPipeSprite,
    ];

    this.preLoader.setPath(this.$config.path, this.$enableCreatorMode);

    this.preLoader.preload(imageList, () => {});
  }

  public play() {
    this.showGame();
    this.showSplash();
  }

  /**
   * SCREEN GAME
   */
  private showGame() {
    window.scrollTo(0, 0);

    this.$levelData = [];

    this.$state.levelStep = 0;
    this.$levels[this.$state.activeLevel].split(";").forEach((levelinfo) => {
      this.$levelData.push(levelinfo.split(","));
    });
    this.$state.levelStep = this.$levelData[0][0];

    document.getElementById("gamecontainer").classList.remove("hidden");
    document.getElementById("gamecontainer").classList.remove("invisible");
    document.getElementById("gamescreen").classList.add("open");

    document.getElementById("grass").style.animationDuration =
      (737 / this.speed()) * 2000 + "ms";
    document.getElementById("land").style.animationDuration =
      (737 / this.speed()) * 1000 + "ms";

    this.$state.gameState = "ready";
    this.setBigScore(false);

    // // Show the click to begin
    this.showSplash();
  }

  /**
   * SCREEN GAMEOVER
   */
  private showSplash() {
    // Prevent other events until ready
    this.$state.gameState = "showsplash";

    // set the defaults (again)
    this.$state.velocity = 0;
    this.$state.position = this.$config.startPosition;
    this.$state.rotation = 30;
    // this.$state.sc = 0;

    // Put player avatar in begin position
    this.updatePlayer();

    // Cleanup / all objects, bullets and buildings objects
    this.clearAll();

    // Make everything animated again
    document.querySelectorAll(".animated").forEach((elm) => {
      elm.classList.remove("pauseAnimation");
    });

    // Start animating the splash screen
    document.getElementById("gamescreen").classList.remove("start");

    setTimeout(() => {
      this.$state.gameState = "splash";
      const avatarBox = this.getAvatarBox();
      this.showBox("playerboxje", avatarBox);
    }, 450);
  }

  private readyToPlay() {
    this.platform.ready();
    // this.showGame();
  }

  public replay() {
    if (this.$state.gameState !== "ready") {
      return;
    }
    // this.setBigScore(true);
    this.showSplash();
  }

  private clearAll() {
    // clear out all the pipes if there are any
    this.$virusses.forEach((v) => v.removeVirus());
    this.$pipes.forEach((v) => v.remove());
    this.$bullets.forEach((v) => v.remove());

    this.$pipes = [];
    this.$virusses = [];
    this.$bullets = [];

    this.cleanup();
  }

  /**
   * SCREEN GAME
   */
  private startGame() {
    if (this.$state.gameState === "game") {
      return;
    }
    this.playSound("gameStart");
    this.$state.gameState = "game";

    this.platform.gamestarted();

    // fade out the splash
    document.getElementById("splash").classList.add("invisible");

    // update the big score
    this.setBigScore();

    // start up our loops
    if (this.loopGameloop) {
      window.clearInterval(this.loopGameloop);
    }

    this.loopGameloop = window.setInterval(() => {
      this.gameloop();
    }, this.$config.framerate);

    if (this.cleanLoop) {
      window.clearInterval(this.cleanLoop);
    }

    this.cleanLoop = window.setInterval(() => {
      this.cleanup();
    }, 1000); // Do a cleanup every second

    // this.pew.gamestarted();

    // jump from the start!
    this.playerJump();
    this.$state.timer = new Date().getTime();
    // Start timers for deploy

    // Start deploying frames
    this.nextEvent();

    this.buildingControler.start(this.$config.deployFirstBuildingTime);

    this.$state.sc = 0;
  }

  private updatePlayer() {
    // rotation
    this.$state.rotation = Math.min((this.$state.velocity / 16) * 90, 90);

    // apply rotation and position
    this.$elm.player.style.transform =
      "rotate(" + this.$state.rotation + "deg)";
    this.$elm.player.style.top = this.$state.position + "px";
  }

  private setEndurance() {
    this.ps(this.$config.timeScore * this.$config.framerate, false);
  }

  private gameloop() {
    if (this.$state.pauseState) {
      return;
    }
    if (this.$state.gameState !== "game") {
      return;
    }

    this.setEndurance();

    // update the player speed/position
    this.$state.velocity += this.$config.gravity;
    this.$state.position += this.$state.velocity;

    // update the player position
    this.updatePlayer();

    const avatarBox = this.getAvatarBox();

    // did we hit the ground?
    if (avatarBox.bottom >= this.$bounding.groundTop) {
      this.playerDead("ground");
      return;
    }

    // have they tried to escape through the ceiling? :o
    if (avatarBox.top <= this.$bounding.ceilBottom) {
      this.$state.position = 0;
      this.playerDead("ceiling");
      return;
    }

    // Walk over all pipes and check of is hit or passed for score
    this.$pipes.forEach((pipe) => {
      if (this.$state.gameState === "score") {
        // already dead. stop checking
        return;
      }
      if (pipe.checkHit(avatarBox, "avatar")) {
        this.playerDead("pipe");
      }
    });

    // check if virus is hit by player
    this.$virusses.forEach((virus) => {
      if (this.$state.gameState === "score") {
        // already dead. stop checking
        return;
      }
      if (virus.checkHit(avatarBox)) {
        this.playerDead("virus");
      }
    });
    this.showBox("playerboxje", avatarBox);
  }

  private getAvatarBox() {
    // create the bounding box

    // Bounding box size is adjusted
    const rotateCorrection = 0; // (Math.sin(Math.abs(this.$state.rotation) / 90) * 8) // 8px per
    const scale = this.$config.hitBoxScale;
    const boxwidth = this.$avatarSize.playerWidth * scale - rotateCorrection;
    const boxheight = this.$avatarSize.playerHeight * scale;
    const dx = this.$avatarSize.playerWidth - boxwidth;
    const dy = this.$avatarSize.playerHeight - boxheight;
    const boxLeft =
      this.$elm.player.offsetLeft +
      dx / 2 +
      this.$config.hitBoxMoveX * this.$avatarSize.playerWidth;
    const boxTop =
      this.$elm.player.offsetTop +
      dy / 2 +
      this.$config.hitBoxMoveY * this.$avatarSize.playerHeight;

    const response = {
      left: boxLeft,
      top: boxTop,
      right: boxLeft + boxwidth,
      bottom: boxTop + boxheight,
      rotation: this.$state.rotation,
      parentBox: {
        top: this.$elm.player.offsetLeft,
        bottom: this.$elm.player.offsetHeight + this.$elm.player.offsetTop,
        right: this.$elm.player.offsetLeft + this.$elm.player.offsetWidth,
        left: this.$elm.player.offsetLeft,
      } as IBox,
    } as IBox;

    return response;
  }

  private cleanup() {
    this.buildingControler.cleanup();

    for (let i = 0; i < this.$virusses.length; i++) {
      this.$virusses[i].clean();
      if (!this.$virusses[i].isActive()) {
        this.$virusses.splice(i, 1);
        return;
      }
    }

    let doMore = true;
    while (doMore) {
      doMore = false;
      for (let i = 0; i < this.$pipes.length; i++) {
        if (doMore) {
          continue;
        }
        this.$pipes[i].clean();
        if (!this.$pipes[i].isActive()) {
          this.$pipes.splice(i, 1);
          doMore = true;
          continue;
        }
      }
    }
    doMore = true;
    while (doMore) {
      doMore = false;
      for (let i = 0; i < this.$virusses.length; i++) {
        if (doMore) {
          continue;
        }
        this.$virusses[i].clean();
        if (!this.$virusses[i].isActive()) {
          this.$virusses.splice(i, 1);
          doMore = true;
          continue;
        }
      }
    }

    doMore = true;
    while (doMore) {
      doMore = false;
      for (let i = 0; i < this.$bullets.length; i++) {
        if (doMore) {
          continue;
        }
        if (!this.$bullets[i].isActive()) {
          this.$bullets.splice(i, 1);
          doMore = true;
          continue;
        }
      }
    }
  }

  private screenClick(e?: MouseEvent) {
    e?.preventDefault();
    e?.stopImmediatePropagation();

    if (this.$state.pauseState) {
      return;
    }

    switch (this.$state.gameState) {
      case "game":
        this.playSound("jump");
        this.playerJump();
        if (this.$config.enableBullets) {
          // this.playSound(this.$config.bulletSound);
          this.addBullet();
        }
        break;
      case "splash":
        this.startGame();
        break;
    }
  }

  private playerJump() {
    this.$state.velocity = this.$config.jump;
  }

  private setBigScore(erase: boolean = false) {
    // this.$elm.score.innerHTML = Math.floor(this.$state.sc)
    // 	.toString()
    // 	.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  public pause() {
    if (this.$state.pauseState) {
      document.querySelectorAll(".animated").forEach((elm) => {
        elm.classList.remove("pauseAnimation");
      });
      this.$state.pauseState = false;
      clearTimeout(this.buildingTimeout);
    } else {
      document.querySelectorAll(".animated").forEach((elm) => {
        elm.classList.add("pauseAnimation");
      });
      this.$state.pauseState = true;
      this.buildingControler.start(0); // Restart building spawn
    }
  }

  private playerDead(reason: string) {
    // it's time to change states. as of now we're considered ScoreScreen to disable left click/flying
    this.$state.gameState = "score";

    this.$elm.player.classList.remove("image-koeman");
    this.$elm.player.classList.add("image-dood");

    // destroy our gameloops
    window.clearInterval(this.loopGameloop);
    window.clearInterval(this.cleanLoop);
    window.clearTimeout(this.buildingTimeout);

    this.loopGameloop = null;
    this.cleanLoop = null;

    // stop animating everything!
    document.querySelectorAll(".animated").forEach((elm) => {
      elm.classList.add("pauseAnimation");
    });

    // Hide bullets
    document.querySelectorAll(".bullet").forEach((elm) => {
      elm.classList.add("invisible");
    });

    // Animate a flash
    document.getElementById("gamecontainer").classList.add("deadflash");

    // Calculate end falling animation of player if not dead to the floor
    if (reason !== "ground") {
      this.$elm.player.classList.add("dead");
      this.$elm.player.style.top =
        this.$bounding.groundTop - this.$avatarSize.playerWidth / 2 + "px";
      this.$elm.player.style.transform = "rotate(60deg)";
    }

    // Take some time before showing the score screen

    // Call Google Analytics with the score
    // this.qliqqer.write(this.sc);
    // this.platform.sendScore(Math.floor(this.$state.sc), true)
    this.platform.gameover(Math.floor(this.$state.sc));
  }

  restart() {
    this.prepareGame();
  }

  private prepareGame() {
    // set the intitial state
    this.$state.velocity = 0;
    this.$state.position = this.$config.startPosition;
    this.$state.rotation = 30;
    // this.$state.sc = 0;
    this.$state.speed = this.$config.speed;

    this.$elm.player.classList.remove("image-dood");
    this.$elm.player.classList.add("image-koeman");

    // Remove all buildings from document
    document.querySelectorAll(".building").forEach((e: Element) => {
      e.remove();
    });

    // Put player avatar in begin position
    // this.updatePlayer();

    // Cleanup / all objects, bullets and buildings objects
    this.clearAll();
    this.showScore();
    this.setBigScore(true);
    this.platform.ready();
  }

  private showScore() {
    this.clearAll();
    // unhide us
    document.getElementById("gamecontainer").classList.remove("deadflash");
    document.getElementById("gamescreen").classList.add("start");
    document.getElementById("splash").classList.remove("invisible");
    this.$elm.player.classList.remove("dead");
  }

  /**
   * Add points to the score and update the score on the screen
   */
  public ps(p: number, showAnim: boolean) {
    this.$state.sc += p;

    // this.qliqqer.set(this.sc);
    this.setBigScore();
    this.platform.sendScore(this.$state.sc, showAnim);
  }

  private deployStep() {
    if (this.$state.pauseState) {
      return;
    }

    if (this.$state.gameState !== "game") {
      return;
    }
    if (this.$state.levelStep >= this.$levelData.length) {
      this.$state.levelStep = 0;
      this.increaseSpeed();
    }

    const stepInfo = this.$levelData[this.$state.levelStep];
    this.$state.levelStep++;
    if (stepInfo[1]) {
      if (this.$config.enableVirus) {
        this.addVirus(stepInfo[1]);
      }
    }
    if (stepInfo[2]) {
      this.addPipe("upper", stepInfo[2]);
    }
    if (stepInfo[3]) {
      this.addPipe("lower", stepInfo[3]);
    }
  }

  increaseSpeed() {
    this.playSound("levelUp");

    this.$state.speed += this.$config.speed * this.$config.speedUp;
    this.$state.deploySpeed = (550 * 135) / this.$config.speed; // increase speed with 10%

    const grass = document.getElementById("grass");
    if (grass) {
      const newSpeed = (737 / this.speed()) * 2000;
      grass.style.animationDuration = newSpeed + "ms";
    }

    const ground = document.getElementById("land");
    if (ground) {
      const newSpeed = (737 / this.speed()) * 1000;
      ground.style.animationDuration = newSpeed + "ms";
    }
  }

  nextEvent() {
    const stepTime = new Date().getTime();
    // Check deploy
    if (stepTime - this.$state.lastDeployTime > this.$state.deploySpeed) {
      this.$state.lastDeployTime = stepTime;
      this.deployStep();
    }
    if (this.$state.gameState === "game") {
      requestAnimationFrame(() => this.nextEvent());
    }
  }

  private addPipe(pipeType, height) {
    if (pipeType === "upper") {
      this.$pipes.push(
        new Pipe(
          pipeType,
          (this.$bounding.flyAreaHeight / 100) * (height * 10),
          this
        )
      );
    }
    if (pipeType === "lower") {
      this.$pipes.push(
        new Pipe(
          pipeType,
          (this.$bounding.flyAreaHeight / 100) * ((11 - height) * 10),
          this
        )
      );
    }
  }

  private addVirus(height) {
    this.$state.virusSwapper++;
    let points: number;
    switch (this.$state.virusSwapper) {
      case 10:
        points = this.$config.virus3Score;
        break;
      case 5:
        points = this.$config.virus2Score;
        break;
      default:
        points = this.$config.virus1Score;
    }
    // const points = (this.$state.virusSwapper === 10 || this.$state.virusSwapper === 5) ? this.$config.virusScore2 : ;
    this.$virusses.push(
      new Virus(
        (this.$bounding.flyAreaHeight / 100) * ((height - 1) * 10),
        this.$state.virusSwapper,
        this,
        points
      )
    );
    if (this.$state.virusSwapper === 10) {
      this.$state.virusSwapper = 0;
    }
  }

  private addBullet() {
    this.$elm.player.classList.add("throw");

    setTimeout(() => {
      this.$elm.player.classList.remove("throw");
    }, 100);

    const y = this.getAvatarBox().top;

    this.$bullets.push(new Bullet(y - this.$config.bulletStartMoveY, this));
  }

  public showBox(id: string, boxsize: IBox, classname = null) {
    if (!this.$enableDebugMode) {
      return;
    }
    if (boxsize.rotation !== undefined) {
      this.showPolygon(id, classname, boxsize);
      return;
    }
    let box = document.getElementById(id);
    if (box === null) {
      box = document.createElement("div");
      box.setAttribute("id", id);
      box.setAttribute("class", "debugbox");
      if (classname) {
        box.classList.add(classname);
      }
      this.addElement(box as HTMLDivElement);
    }

    box.setAttribute(
      "style",
      "top: " +
        boxsize.top +
        "px; left: " +
        boxsize.left +
        "px; width: " +
        (boxsize.right - boxsize.left) +
        "px; height:" +
        (boxsize.bottom - boxsize.top) +
        "px;"
    );
  }

  showPolygon(id: string, classname: string, boxSize: IBox) {
    const poly = convertBoxToPolygon(boxSize);
    const size = poly
      .map((p) => {
        return p.x + "," + p.y;
      })
      .join(" ");
    const html =
      '<svg width="100%" height="100%" id="debugpoly' +
      id +
      '" class="debugPolygon"><polygon points="' +
      size +
      '" style="fill:none;stroke:red;stroke-width:2" /></svg>';

    let svg = document.getElementById("debugpoly" + id);
    if (svg) {
      this.$elm.flyArea.removeChild(svg);
    }

    const template = document.createElement("template");
    template.innerHTML = html;
    svg = template.content.firstChild as HTMLElement;
    this.$elm.flyArea.append(svg);

    // box.setAttribute('style', 'top: 0; left: ' + boxSize.left + 'px; width: ' + (boxSize.right - boxSize.left) + 'px; height:' + (boxSize.bottom - boxSize.top) + 'px;');
  }

  public removeBox(id) {
    if (document.getElementById(id)) {
      document.getElementById(id).remove();
    }
  }
}
