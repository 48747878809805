import { Game } from './Game';

export type BuildingType = 'stadion' | 'molen';

export class Building {
    game: Game;
    $state: {
        lastBuilding: BuildingType | null,
        buildingTimeout: number;
        active: boolean
    }

    constructor(game: Game) {
      this.game = game;
      this.$state = {
        lastBuilding: null,
        buildingTimeout: 0,
        active: false
      }
    }

    public start(delay: number) {
      // If already activly deploying. Do nothing
      if (this.$state.active) {
        return;
      }

      // Prevent respawn
      this.$state.active = true;

      this.$state.buildingTimeout = window.setTimeout(() => {
        this.deployBuilding();
      }, delay);
    }

    public cleanup() {
      document.querySelectorAll('.building').forEach((e: Element) => {
        if ((e as HTMLDivElement).offsetLeft === -1000) {
          e.remove();
        }
      });
    }

    public deployBuilding() {
      // Do not deploy when state is not game
      if (this.game.gameState() !== 'game') {
        this.$state.active = false
        return;
      }

      // Do not deploy when paused
      if (this.game.isPaused()) {
        this.$state.active = false
        return;
      }

      let nextTime: number;
      let nextBuildingType: BuildingType;

      // Random between 0 and 4
      // Deploy stadium only when 2 (chance is 1 out of 5)
      switch (Math.floor(Math.random() * 4)) {
        case 2:
          nextBuildingType = 'stadion';
          nextTime = 40000;
          break;
        default:
          nextBuildingType = 'molen';
          nextTime = 20000;
      }

      // Do not deploy stadion if last building was also a stadion
      if (this.$state.lastBuilding === 'stadion') {
        nextBuildingType = 'molen';
        nextTime = 15000;
      }

      const multiplier = nextBuildingType === 'molen' ? 0.5 : 1

      const newBuilding = document.createElement('div');
      // CSS Class for type of building + customCSS for image location of the building
      newBuilding.setAttribute('class', 'building animated ' + nextBuildingType + ' image-' + nextBuildingType);
      newBuilding.style.animationDuration = 1400 / this.game.speed() * 5000 * multiplier + "ms";

      // Append html
      this.game.addElement(newBuilding as HTMLDivElement);

      // Remember last building
      this.$state.lastBuilding = nextBuildingType;

      // Next deploy time is based on deployed type
      this.$state.buildingTimeout = window.setTimeout(
        () => this.deployBuilding(),
        nextTime
      );
    }
}
