import { Game } from './Game';
import { IBox } from './Intersect';

export class Virus {
    static virusIndex = 0;
    private elmVirus: HTMLDivElement;
    private version: number;
    private game: Game;
    active = true;
    id: number;
    virusBox: IBox;
    private points: number;
    constructor(yPos: number, version: number, game: Game, points: number) {
      this.game = game;
      this.points = points;
      // Create DOM element
      this.elmVirus = document.createElement('div');
      switch (version) {
        case 5:
          this.elmVirus.setAttribute('class', 'virus animated altimage image-corona-alt');
          break;
        case 10:
          this.elmVirus.setAttribute('class', 'virus animated altimage2 image-corona-alt2');
          break;
        default:
          this.elmVirus.setAttribute('class', 'virus animated image-corona');
      }

      this.elmVirus.setAttribute('style', 'top: ' + yPos + 'px');
      const duration = 1400 / this.game.speed() * 1000 + "ms";
      this.elmVirus.style.animation = `animPipe ${duration} linear, animVirus 500ms infinite`

      this.game.addElement(this.elmVirus);

      this.version = version;
      // Register ID
      Virus.virusIndex++;
      this.id = +Virus.virusIndex;

      // Create box var
      this.virusBox = {
        top: this.elmVirus.offsetTop,
        left: this.elmVirus.offsetLeft - 20,
        bottom: this.elmVirus.offsetTop + 80 * this.game.config().virusScale,
        right: this.elmVirus.offsetLeft + 80 * this.game.config().virusScale - 20
      };
    }

    public isActive() {
      return this.active;
    };

    public getBox() {
      this.virusBox.left = this.elmVirus.offsetLeft - 5;
      this.virusBox.right = this.virusBox.left + 80 * this.game.config().virusScale - 5;
      return this.virusBox;
    };

    public checkHit(sourceBox: IBox) {
      if (!this.active) {
        return false;
      }

      this.virusBox.left = this.elmVirus.offsetLeft;
      this.virusBox.right = this.virusBox.left + 80 * this.game.config().virusScale;
      this.game.showBox('virus' + this.id, this.virusBox);
      this.game.showBox('playerboxje', sourceBox);
      if (sourceBox.left < this.virusBox.right && this.virusBox.left < sourceBox.right && sourceBox.top < this.virusBox.bottom && this.virusBox.top < sourceBox.bottom) {
        return true; // virus zit er onder
      }

      return false;
    };

    public clean() {
      if (this.elmVirus.offsetLeft === -100) {
        this.removeVirus();
      }
    };

    removeVirus_() {
      this.active = false;
      this.elmVirus.remove();
      this.game.removeBox('virus' + this.id);
    };

    public removeVirus() {
      this.removeVirus_();
    };

    public hit() {
      if (!this.active) {
        return;
      }

      if (this.version === 10 || this.version === 5) {
        this.game.ps(this.points, true);
      } else {
        this.game.ps(this.points, true);
      }

      this.active = false;

      this.elmVirus.classList.add('hit');
      const duration = 1400 / this.game.speed() * 1000 + "ms";

      this.elmVirus.style.animation = `animPipe ${duration} linear, animKillVirus ${this.game.config().virusKillAnimationTime}ms`


      this.game.playSound("virus");

      setTimeout(() => {
        this.removeVirus();
      }, this.game.config().virusKillAnimationTime);
    };
};
