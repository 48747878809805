import { IConfig } from './GameConfig';

export function patchConfig(config: IConfig) {
  const urlParams = new URLSearchParams(window.location.search);

  // Patch settings
  if (urlParams.get('s')) {
    const settings = atob(urlParams.get('s')).split('\n').filter(s => s.trim() !== '').map(s => {
      return { name: s.split('=')[0].trim(), value: s.split('=')[1].trim() };
    });
    settings.forEach(s => {
      if (!isNaN(+s.value)) {
        config[s.name] = +s.value;
      } else if (s.value === 'true') {
        config[s.name] = true;
      } else if (s.value === 'false') {
        config[s.name] = false;
      } else {
        config[s.name] = s.value;
      }
      if (s.name === 'customLevel') {
        config.levels = [];
        config.levels.push(s.value);
      }
    }
    )
    console.log("CONFIG", config)
  }
}
